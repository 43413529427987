import {useTranslation} from 'react-i18next'
import { useMsal } from '@azure/msal-react';

const Navigation =() => {
    const {t} = useTranslation();
    const {accounts} = useMsal();
    const roles = accounts[0]?.idTokenClaims?.roles;
    const isAdmin = roles.includes('SII_Admin');
    const isEquipment = roles.includes('550_ISG_Read');
    const isEquipment1 = roles.includes('540_MAC_Read');
    

    return (
        [
                       {
                key: 'H',
                name: t('Navigation.Home'),
                url: '/dashboard',
                iconProps: {
                    iconName: 'Home',
                },
            },
            {
                key: 'R',
                name: t('Navigation.Parts'),
                url: '/documento/ingreso',
                iconProps: {
                    iconName: 'WebAppBuilderFragmentCreate',
                },
            },
            {
                key: 'E',
                name: t('Navigation.Equipment'),
                url: '/documento/eq/ingreso',
                iconProps: {
                    iconName: 'DeliveryTruck',
                },
            },
            {
                key: 'L',
                name: t('Navigation.Pending'),
                url: '/documento',
                iconProps: {
                    iconName: 'SeeDo',
                },
            },

           ( (isAdmin || isEquipment ||isEquipment1 ) &&
            {
                key: 'E',
                name: t('Navigation.Tax'),
                url: '/documentoeqm',
                iconProps: {
                    iconName: 'SeeDo',
                },               
            }),
            ( (isAdmin || isEquipment || isEquipment1 ) &&
            {
                key: 'E',
                name: t('Navigation.AWB'),
                url: '/documentoeq',
                iconProps: {
                    iconName: 'SeeDo',
                },                
            }),

            // Add more navigation items as needed
        ]
    )
};
export default Navigation;
