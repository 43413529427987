import { DetailsList, IconButton, SelectionMode, SearchBox} from "@fluentui/react";
import {useNavigate} from "react-router-dom";
import {useCallback, useEffect, useState} from "react";
import {connect} from "react-redux";
import axios from 'axios';
import {useTranslation} from 'react-i18next'
import config from '../Admin/config'
import { useMsal } from '@azure/msal-react';


const VistaDetalleDocumentosEQM = ({companyId}) => {
    const navigate = useNavigate();
    const [documentos, setDocumentos] = useState([]);
    const [documentosConsolidados, setDocumentosConsolidados] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const {accounts} = useMsal();
    const roles = accounts[0]?.idTokenClaims?.roles;


    
    const {t, i18n} = useTranslation();

    const handleEditClick = (ID_Documento)=> {
        navigate(`/documento/${ID_Documento}`);
    }

    const handleSearch = (newValue) => {
        setSearchValue(newValue);
    
        const filtered = documentosConsolidados.filter(item =>
          item.transportDocumentNumber.toLowerCase().includes(newValue.toLowerCase()))
        ;
    
        setFilteredData(filtered);
      };

    const handleCostosClick = (ID_Documento, reptoEquipoId)=> {
      if (reptoEquipoId === "Part"){
        reptoEquipoId ="R"
      }  
      navigate(`/costoseqm/${ID_Documento}/${reptoEquipoId}`);
    }

    //const handleFacturasClick = (item)=> {
        //navigate(`/facturas/${item.transportDocumentNumberP}?provider=${item.providerId}&incoterm=${item.icoterm}`);
      //}
    
    const handleConsolidar = (ID_Documento)=> {
      navigate(`/consolidado2/${ID_Documento}`);
    }

    const handleConsolidadoHistorico = (ID_Documento)=> {
        navigate(`/consolidado/historico/${ID_Documento}`);
      }

    const fetchDocumentosData = useCallback(
        async () => {
            try {
              const response = await axios.get(`${config.apiUrl}api/documents/MACISGEQ`, {
                params: {
                  company: companyId,
                  locale : i18n.language,
                  
                }
              });
              let data = response.data;
              setDocumentos(data);
            } catch (error) {
              console.error('Error fetching data:', error);
            }
        },[companyId, i18n.language, roles]); 

    useEffect(() => {
        fetchDocumentosData();
    }, [companyId, fetchDocumentosData]);

    const fetchDocumentosConsolidadosData = useCallback(async () => {
        try {
            const response = await axios.get(`${config.apiUrl}api/documents/consolidate`, {
                params: {
                    company: companyId,
                    locale: i18n.language,
                },
            });
            let data = response.data;

            setDocumentosConsolidados(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [companyId, i18n.language, roles]);

    useEffect(() => {
        fetchDocumentosConsolidadosData();
    }, [companyId, fetchDocumentosConsolidadosData]);
    

    return (
        <div  className="card">
            <h1>{t('View.Header')}</h1>
                <DetailsList
                    items={documentos}
                    selectionMode={SelectionMode.none}
                    columns={[
                        { key: 'id', name: t('View.Number'), fieldName: 'transportDocumentNumber', minWidth: 150, maxWidth: 150 },
                        //{ key: 'idp', name: t('NumberM'), fieldName: 'transportDocumentNumberP', minWidth: 150, maxWidth: 150 },
                        { key: 'reptoEquipo', name: t('View.Type'), fieldName: 'reptoEquipo', minWidth: 100, maxWidth: 200 },
                        { key: 'transportDocumentDate', name: t('View.Date'), fieldName: 'transportDocumentDate', minWidth: 100, maxWidth: 200 },
                        { key: 'levanteNumber', name: t('View.Release'), fieldName: 'levanteNumber', minWidth: 100, maxWidth: 100 },
                        { key: 'landedCostNumber', name: 'Landed Cost', fieldName: 'landedCostNumber', minWidth: 100, maxWidth: 200 },
                        //{ key: 'Equipment', name: t('Equipment'), fieldName: 'equipmentId', minWidth: 150, maxWidth: 150 },
                        //{ key: 'ItemID', name: t('ItemId'), fieldName: 'itemId', minWidth: 150, maxWidth: 150 },
                        {
                            key: 'actions',
                            name: t('View.Actions'),
                            minWidth: 180,
                            maxWidth: 200,
                            onRender: (item) => (
                                <>
                                    <IconButton
                                        iconProps={{ iconName: 'Edit' }}
                                        title={t('View.Edit')}
                                        ariaLabel={t('View.Edit')}
                                        onClick={() => handleEditClick(item.transportDocumentNumber)}
                                    />
                                    <IconButton
                                    iconProps={{ iconName: 'Calculator' }}
                                    title={t('View.Costs')}
                                    ariaLabel={t('View.Costs')}
                                    className="button"
                                    onClick={()=> handleCostosClick(item.transportDocumentNumber, item.reptoEquipo)}
                                    />
                                    <IconButton
                                        iconProps={{ iconName: 'Lock' }}
                                        title={t('View.Consolidate')}
                                        ariaLabel={t('View.Consolidate')}
                                        className="button"
                                        onClick={()=> handleConsolidar(item.transportDocumentNumber)}
                                    />
                                </>
                            ),
                        },
                    ]}
                />

            
            <h2>{t('View.Consolidated')}</h2>
                <SearchBox
                        placeholder="Document"
                        value={searchValue}
                        onChange={(event, newValue) => handleSearch(newValue)}
                />
                {searchValue && (
                    
                <DetailsList
                    items={filteredData}
                    selectionMode={SelectionMode.none}
                    columns={[
                        { key: 'id', name: t('View.Number'), fieldName: 'transportDocumentNumber', minWidth: 250, maxWidth: 300 },
                        { key: 'reptoEquipo', name: t('View.Type'), fieldName: 'reptoEquipo', minWidth: 100, maxWidth: 200 },
                        { key: 'transportDocumentDate', name: t('View.Date'), fieldName: 'transportDocumentDate', minWidth: 100, maxWidth: 200 },
                        { key: 'levanteNumber', name: t('View.Release'), fieldName: 'levanteNumber', minWidth: 100, maxWidth: 200 },
                        { key: 'landedCostNumber', name: 'Landed Cost', fieldName: 'landedCostNumber', minWidth: 100, maxWidth: 200 },
                        {
                            key: 'actions',
                            name: t('View.Actions'),
                            minWidth: 180,
                            maxWidth: 200,
                            onRender: (item) => (
                                <>
                                    <IconButton
                                        iconProps={{ iconName: 'AddToShoppingList' }}
                                        title="Review"
                                        ariaLabel={t('View.Review')}
                                        className="button"
                                        onClick={()=> handleConsolidadoHistorico(item.transportDocumentNumber)}
                                    />
                                </>
                            ),
                        },
                    ]}
                />)}
        </div>
    );
};

const mapStateToProps = (state) => ({
    companyId: state.companyId,
});

export default connect(mapStateToProps)(VistaDetalleDocumentosEQM);
