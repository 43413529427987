import React, { useState, useEffect, useCallback } from 'react';
import { PrimaryButton, TextField, Dropdown, Stack, DetailsList, SelectionMode,  Dialog, DialogType, DialogFooter, SearchBox, Checkbox } from '@fluentui/react';
import axios from 'axios';
import {useNavigate, useParams} from 'react-router-dom';
import {connect} from "react-redux";
import {useTranslation} from 'react-i18next';
import numeral from 'numeral'
import config from '../Admin/config'
import { useMsal } from '@azure/msal-react';




const FormularioAgregarCostos = ({ companyId}) => {
    const { id } = useParams();
    const {reptoEquipoId} = useParams();
    const [costs, setCosts] = useState([]);
    const [costType, setCostType] = useState('');
    const [currency, setCurrency] = useState('');
    const [value, setValue] = useState('');
    const [nitTercero, setNitTercero] = useState('');
    const [selectedCost, setSelectedCost] = useState('');
    const [isDialogOpen, setIsDialogOpen] = useState(false); 
    const [costTypes, setCosttypes] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const formattedValue = numeral(value).format('$0,0.00');
    const [searchValue, setSearchValue] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [isSelected, setIsSelected] = useState(false);
    const [NitOptions, setNitOptions] = useState([]);
    const [totalAmmount, setTotalAmmount] = useState(0);
    numeral.locale('en')
    const navigate = useNavigate();
    const {t} = useTranslation();

    const {accounts} = useMsal();
    const roles = accounts[0]?.idTokenClaims?.roles;
    const isAdmin = roles.includes('SII_Admin');
    const isEquipment = roles.includes('550_ISG_Read');
    const isEquipment1 = roles.includes('540_MAC_Read');

    const handleCostTypeChange = (_, item) => {
        setCostType(item ? item.key : '');
    };

    const handleCurrencyChange = (_, option) => {
        setCurrency(option.key);
    };

    const handleValueChange = (_, newValue) => {
        setValue(newValue);
    };
    const handleNitChange = (_, newValue) => {
        setNitTercero(newValue);
    };

    const handleEditCost = (item) => {
        if(roles.includes("SII_Admin"))
        {            
            setSelectedCost(item.idCost)
            setCostType(item.idCostType)
            setCurrency(item.currency)
            setValue(item.value)
            setNitTercero(item.nitTercero)
            setIsDialogOpen(true);
        }
        else
        {
            alert(t('Costs.Error_update'));
        }

    };

    const handleDeleteCost = async (costId) => {
        if(roles.includes("SII_Admin"))
            {
                try {
                    await axios.delete(`${config.apiUrl}api/cost/${costId}`);
                   fetchCostsData();
               } catch (error) {
                   alert(t('Costs.Error_delete'))// Handle the error gracefully here, e.g., show an error message to the user
               }
               setCostType('');
               setCurrency('');
               setValue('');
               setNitTercero('')
               setSelectedCost('');
               setIsDialogOpen(false);
            }
            else
            {
                alert(t('Costs.Error_delete'));
            }
    

    };

    const handleSaveEdit = async () => {

        const record = {
            costTypeId: costType,
            transportDocument: id,
            currency: currency,
            nitTercero: parseFloat(nitTercero),
            value: parseFloat(value)
            };

        try {
             await axios.put(`${config.apiUrl}api/cost/${selectedCost}`, record);
            fetchCostsData();
        } catch (error) {
            alert(t('Costs.Error_update'))// Handle the error gracefully here, e.g., show an error message to the user
        }
        setCostType('');
        setCurrency('');
        setValue('');
        setNitTercero('')
        setSelectedCost('');
        setIsDialogOpen(false);
    };

    const handleCancelEdit = () => {
        setSelectedCost('');
        setIsDialogOpen(false);
    };

    const handleAddCost = async () => {
        const record = {
          costTypeId: costType,
          transportDocument: id,
          currency: currency,
          nitTercero: nitTercero,
          value: parseFloat(value)
        };
        try {
          await axios.post(`${config.apiUrl}api/cost`, record);
          fetchCostsData();
        } catch (error) {
          alert(t('Costs.Error_create'))// Handle the error gracefully here, e.g., show an error message to the user
        }
        setIsSelected(false);
        setCostType('');
        setCurrency('');
        setValue('');
        setNitTercero('')
        setFilteredData([])
      };

    const handleCheckboxChange = (item) => {
    if (isSelected) {
        setFilteredData(NitOptions);
        setNitTercero(null);
    } else {
        setFilteredData([item]);
        setNitTercero(item.nit);
    }
    
    setIsSelected(!isSelected);
    };
    const columnsNit=[
        { key: 'nit', name: 'Nit', fieldName: 'nit', minWidth: 100, maxWidth: 200 },
        { key: 'name', name: t('Costs.Name'), fieldName: 'providerName', minWidth: 100, maxWidth: 200 },
        
        {
            key: 'actions',
            name: 'actions',
            minWidth: 180,
            maxWidth: 200,
            onRender: (item) => (
                <>
                    <Checkbox
                    checked = {isSelected}
                    onChange={() => handleCheckboxChange(item)}
                  />
                </>
            ),
        },
    ];
    
      const handleSearch = (newValue) => {
        setIsSelected(false);
        setSearchValue(newValue);
    
        const filtered = NitOptions.filter(item =>
          item.nit.includes(newValue) || item.providerName.toLowerCase().includes(newValue.toLowerCase())
        );
    
        setFilteredData(filtered);
      };

    const columns = [
        { key: 'costTypeName', name:  t('Costs.Type'), fieldName: 'costTypeName', minWidth: 300, onRender: (item) => (
            <span>{item.idCostType} - {item.costTypeName}</span>
          ) },
        { key: 'currency', name:  t('Costs.Currency'), fieldName: 'currency', minWidth: 100 },
        { key: 'value', name: t('Costs.Value'), fieldName: 'value', minWidth: 100 , onRender: (item) => numeral(item.value).format('$0,0.00')},
        { key: 'nit', name: 'Nit', fieldName: 'nitTercero', minWidth: 100},
        {        
            key: 'opciones',
            name: t('Costs.Options'),
            fieldName: 'opciones',
            minWidth: 200,
            onRender: (item) => (
                <div>
                    <PrimaryButton text={t('Costs.Edit')} onClick={() => handleEditCost(item)} />
                    <PrimaryButton text={t('Costs.Delete')} onClick={() => handleDeleteCost(item.idCost)} style={{ backgroundColor: '#f00' }} />
                </div>
            )
        }
    ];

    const editDialogContentProps = {
        type: DialogType.normal,
        title: 'Editar Costo',
        closeButtonAriaLabel: 'Cerrar'
    };

    const fetchCostsData = useCallback(async () => {
        try {
          const response = await axios.get(`${config.apiUrl}api/cost`, { params: { transportDocument: id , company: companyId} });
          const currentCosts = response.data;
          setCosts(currentCosts);
        } catch (error) {
          console.error('Error fetching costs data:', error);
        }
      }, [id, companyId]);
    
    useEffect(() => {
        fetchCostsData();
    }, [fetchCostsData, id]);    

         
    useEffect(() => {
        const fetchCostTypeData = async () => {
            try {
                const response = await axios.get(`${config.apiUrl}api/cost/type`,{params:{company: companyId , reptoEquipoId : reptoEquipoId.at(0)}});
                const data = response.data;

                const dropdownOptions = data.map((item) => ({
                    key: item.idCostType,
                    text: item.idCostType  + " - " + item.costTypeName,
                }));
                setCosttypes(dropdownOptions);
            } catch (error) {
                console.error('Error fetching cost types data:', error);
            }
        };
        fetchCostTypeData();
    }, [id, companyId, reptoEquipoId]);

    useEffect(() => {
        const fetchNitsData = async () => {
            try {
                const response = await axios.get(`${config.apiUrl}api/providers/all`,{params:{company: companyId}});
                const data = response.data;
                setNitOptions(data);
            } catch (error) {
                console.error('Error fetching cost types data:', error);
            }
        };
        fetchNitsData();
    }, [companyId]);

    useEffect(() => {
        const fetchCurrenciesData = async () => {
            try {
                const response = await axios.get(`${config.apiUrl}api/currency`, { params: { company: companyId } });
                const data = response.data;
                    const dropdownOptions = data.map((item) => ({
                    key: item,
                    text: item,
                }));
                
                setCurrencies(dropdownOptions);
            } catch (error) {
                console.error('Error fetching currencies data:', error);
            }
        };
    
        fetchCurrenciesData();
    }, [companyId, id]);

    useEffect(() => {
        const fetchCurrenciesData = async () => {
            try {
                const response = await axios.get(`${config.apiUrl}api/facturas/total/${id}`);
                const data = response.data;
                setTotalAmmount(data);
            } catch (error) {
                console.error('Error fetching total ammount data:', error);
            }
        };
    
        fetchCurrenciesData();
    }, [id]);
    

    return (

        <div className='card'>
            <h1> {id} </h1>
            <p>{ t('Costs.Total')  + numeral(totalAmmount).format('$0,0.00')}</p>
            <h2>{t('Costs.Header')}</h2>
            <Stack tokens={{ childrenGap: 10 }}>
                <SearchBox
                        placeholder="Nit"
                        value={searchValue}
                        onChange={(event, newValue) => handleSearch(newValue)}
                />
                {searchValue && (
                    <DetailsList
                    items={filteredData}
                    selectionMode={SelectionMode.none}
                    columns={columnsNit}
                />)}
                <Dropdown
                    label={t('Costs.Type')}
                    options={costTypes}
                    onChange={handleCostTypeChange}
                    selectedKey={costType}
                />
                <Dropdown
                    label={t('Costs.Currency')}
                    selectedKey={currency}
                    options={currencies}
                    onChange={handleCurrencyChange}
                />
                
                <TextField label={t('Costs.Value')} value={value} onChange={handleValueChange} type="number" />
                <div>{formattedValue}</div>   
                <PrimaryButton text={t('Costs.Button')} onClick={handleAddCost} />
            </Stack>

            <h2>{t('Costs.ListCost')}</h2>
            {costs.length === 0 ? (
                <p>No hay costos agregados.</p>
            ) : (
                <DetailsList
                    items={costs}
                    columns={columns}
                    selectionMode={SelectionMode.none}
                />
            )}

            <PrimaryButton text={t('Costs.Save')} onClick={() => navigate(`/consolidado/${id}`)} />
            <p></p>
            <PrimaryButton 
                text={t('Costs.SaveCosts')} 
                onClick={() => navigate(`/consolidado2/${id}`)} 
                style={{ display: (isAdmin || isEquipment || isEquipment1) ? 'block' : 'none' }} 
            />


            <Dialog hidden={!isDialogOpen} dialogContentProps={editDialogContentProps}>
                <Stack tokens={{ childrenGap: 10 }}>
                    <Dropdown
                        label={t('Costs.Type')}
                        selectedKey={costType}
                        options={costTypes}
                        onChange={handleCostTypeChange}
                    />
                    <Dropdown
                        label={t('Costs.Currency')}
                        value = {currency}
                        selectedKey={currency}
                        options={currencies}
                        onChange={handleCurrencyChange}
                    />
                    <TextField 
                        label={t('Costs.Value')} 
                        value = {nitTercero}
                        onChange={handleNitChange} 
                        type="number" 
                    />
                    <TextField 
                        label={t('Costs.Value')} 
                        value = {value}
                        onChange={handleValueChange} 
                        type="number" 
                    />
                    <div>{formattedValue}</div>    
                </Stack>
                <DialogFooter>
                    <PrimaryButton text={t('Costs.Save')} onClick={handleSaveEdit} />
                    <PrimaryButton text={t('Costs.Cancel')} onClick={handleCancelEdit} style={{ backgroundColor: '#f00' }} />
                </DialogFooter>
            </Dialog>
        </div>
    );
};
const mapStateToProps = (state) => ({
    companyId: state.companyId,
});

export default  connect(mapStateToProps)(FormularioAgregarCostos);
