import React, { useState, useEffect, useCallback } from 'react';
import {DetailsList, SelectionMode, IconButton, PrimaryButton } from '@fluentui/react';
import axios from 'axios';
import {connect} from "react-redux";
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import numeral from 'numeral'; // Import numeral library
import config from '../Admin/config'



const FormularioAgregarFacturas = ({companyId}) => {
    const [bills, setBills] = useState([]);
    const [asociatedBills, setAssociatedBills] = useState([]);
    const { id } = useParams();
    const location = useLocation();
    const queryParams = queryString.parse(location.search);
    const navigate = useNavigate();

    const {t} = useTranslation();
    numeral.locale('en'); // Change 'en' to the desired locale

    const fetchFreeBillData = useCallback(async () => {
        try {
          const response = await axios.get(`${config.apiUrl}api/facturas`, 
          { params: {
             company: companyId ,
             provider: queryParams.provider,
             incoterm: queryParams.incoterm,}});
          setBills(response.data);
        } catch (error) {
          console.error('Error fetching bills data:', error);
        }
    }, [companyId, queryParams.incoterm, queryParams.provider]);
      
    useEffect(() => {
        fetchFreeBillData();
    }, [fetchFreeBillData, companyId]);
      
    const fetchAssociatedBillData = useCallback(async () => {
        try {
          const response = await axios.get(`${config.apiUrl}api/facturas/${id}`, { params: {company: companyId} });
          setAssociatedBills(response.data);
        } catch (error) {
          console.error('Error fetching costs data:', error);
        }
      }, [id, companyId]);
      
      useEffect(() => {
        fetchAssociatedBillData();
    }, [fetchAssociatedBillData, id]);

    const handleDeAsociateBill = async (billId) => {
        try {
            await axios.delete(`${config.apiUrl}api/facturas/`, { params: { cod_Factura: billId } });
            fetchFreeBillData();
            fetchAssociatedBillData();
        } catch (error) {
            alert(t('Bill.Error_Delete')); // Handle the error gracefully here, e.g., show an error message to the user
        }
    };
    
    const handleAsociateBill = async (item) => {
        const record = {
            numeroCuenta: item.numeroCuenta,
            numeroFactura: item.numeroFactura,
            fechaFactura: item.fechaFactura,
        };
    
        try {
            await axios.post(`${config.apiUrl}api/facturas/${id}`, record, { params: { company: companyId } });
            fetchFreeBillData();
            fetchAssociatedBillData();
        } catch (error) {
            alert(t('Bill.Error_Delete')); // Handle the error gracefully here, e.g., show an error message to the user
        }
    };

    const handleUpdateDocument = async() => {            
        try {
            
            await axios.post(`${config.apiUrl}api/documents/Insert_document_MAC/${id}`);  

            navigate(`/costos/${id}/Equipment`);

        } 
        
        catch (error) {
            alert(t(error));
            navigate(`/costos/${id}/Equipment`);
        }
        
    }

    const columnsAssociated = [
        { key: 'AccountNumber', name:  t('Bills.Account'), fieldName: 'numeroCuenta', minWidth: 150 },
        { key: 'BillNumber', name:  t('Bills.Bill'), fieldName: 'numeroFactura', minWidth: 150 },
        { key: 'BillDate', name: t('Bills.Date'), fieldName: 'fechaFactura', minWidth: 100 },
        { key: 'BillAmount', name: t('Bills.Amount'), fieldName: 'monto', minWidth: 100 , onRender: (item) => numeral(item.monto).format('$0,0.00') },
        {
            key: 'opciones',
            name: t('Costs.Options'),
            fieldName: 'opciones',
            minWidth: 200,
            onRender: (item) => (
                <div>
                    <IconButton
                        iconProps={{ iconName: 'BoxMultiplySolid' }}
                        title="Deasociate"
                        ariaLabel="Deasociate"
                        className="button"
                        onClick={() => {
                            const finalString = `${item.numeroFactura}_${item.numeroCuenta}_${item.fechaFactura}_${companyId}`;
                            handleDeAsociateBill(finalString, item);
                            }}
                    />
                </div>
            )
        }
    ];

    const columnsfree = [
        { key: 'AccountNumber', name:  t('Bills.Account'), fieldName: 'numeroCuenta', minWidth: 150 },
        { key: 'BillNumber', name:  t('Bills.Bill'), fieldName: 'numeroFactura', minWidth: 150 },
        { key: 'BillDate', name: t('Bills.Date'), fieldName: 'fechaFactura', minWidth: 100 },
        { key: 'BillAmount', name: t('Bills.Amount'), fieldName: 'monto', minWidth: 100, onRender: (item) => numeral(item.monto).format('$0,0.00') },
        {
            key: 'opciones',
            name: t('Costs.Options'),
            fieldName: 'opciones',
            minWidth: 200,
            onRender: (item) => (
                <div>
                    <IconButton
                        iconProps={{ iconName: 'BoxCheckmarkSolid' }}
                        title="Asociate"
                        ariaLabel="Asociate"
                        className="button"
                        onClick={() => handleAsociateBill(item)}
                    />
                </div>
            )
        }
    ];
      
    return (

        <div className='card'>
            <h1> {id} </h1>
            <h2>{t('Bills.Header_added')}</h2>
            {asociatedBills.length === 0 ? (
                <p>{t('Bills.Error_added')}</p>
                ) : (
                <DetailsList
                    items={asociatedBills}
                    columns={columnsAssociated}
                    selectionMode={SelectionMode.none}
                />
            )}
            <h2>{t('Bills.Header_free')}</h2>
            {bills.length === 0 ? (
                <p>{t('Bills.Error_free')}</p>
            ) : (
                <DetailsList
                    items={bills}
                    columns={columnsfree}
                    selectionMode={SelectionMode.none}
                />
            )}
            <PrimaryButton text={t('Edit.Save')} onClick={handleUpdateDocument} />
        </div>
    );
};
const mapStateToProps = (state) => ({
    companyId: state.companyId,
});

export default  connect(mapStateToProps)(FormularioAgregarFacturas);