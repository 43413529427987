import VistaDetalleDocumentos from "./VistaDetalleDocumentos";
import VistaDetalleDocumentosEQ from "./VistaDetalleDocumentosEQ";
import VistaDetalleDocumentosEQM from "./VistaDetalleDocumentosEQM";
import FormularioCrearDocumento from "./FormularioCrearDocumento";
import FormularioEditarDocumento from "./FormularioEditarDocumento";
import FormularioAgregarFacturas from "./FormularioAgregarFacturas";
import FormularioAgregarCostos from "./FormularioAgregarCostos";
import FormularioConsolidado from "./FormularioConsolidado";
import FormularioConsolidadoHistorico from "./FormularioConsolidadoHistorico"
import Dashboard from "./Dashboard";
import FormularioCrearDocumentoEQ from "./FormularioCrearDocumentoEQ";
import FormularioAgregarCostosEQ from "./FormularioAgregarCostosEQ";
import FormularioConsolidado1 from "./FormularioConsolidado1";
import FormularioConsolidado2 from "./FormularioConsolidado2";
import FormularioAgregarCostosEQM from "./FormularioAgregarCostosEQM";
import FormularioEditarDocumentoEQ from "./FormularioEditarDocumentoEQ";

const facturasRoutes = [
    { path: '/documento/ingreso', element: <FormularioCrearDocumento />},
    { path: '/documento/eq/ingreso', element: <FormularioCrearDocumentoEQ />},
    { path: '/documento', element: <VistaDetalleDocumentos />},
    { path: '/documentoeqm', element: <VistaDetalleDocumentosEQM/>},
    { path: '/documentoeq', element: <VistaDetalleDocumentosEQ/>},
    { path: '/documento/:id', element: <FormularioEditarDocumento />},
    { path: '/documentoeq/:id/:equipmentId', element: <FormularioEditarDocumentoEQ />},
    { path: '/facturas/:id', element: <FormularioAgregarFacturas />},
    { path: '/costos/:id/:reptoEquipoId', element: <FormularioAgregarCostos />},
    { path: '/costoseqm/:id/:reptoEquipoId', element: <FormularioAgregarCostosEQM />},
    { path: '/costoseq/:id/:reptoEquipoId/:equipmentId', element: <FormularioAgregarCostosEQ />},
    { path: '/consolidado/:id', element: <FormularioConsolidado /> },
    { path: '/consolidado2/:id', element: <FormularioConsolidado2 /> },
    { path: '/consolidado1/:id/:equipmentId', element: <FormularioConsolidado1 /> },
    { path: '/consolidado/historico/:id', element: <FormularioConsolidadoHistorico /> },
    {path: '/dashboard', element: <Dashboard />}
    
];

export default facturasRoutes;
